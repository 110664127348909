import React from 'react';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import { useToggle } from '../../utils/hooks/useToggle';
import Button from '../Button/Button';
import QRCode from 'react-qr-code';
import Modal from '../Modal/Modal';
import { YOU } from '../../utils/constants';
import { useIsMobile } from '../../utils/hooks/useIsMobile';

interface IPhoneNumber {
  number: string;
  showCallIcon?: boolean;
  isMe?;
  showQR?: boolean;
  setShowQR: () => void;
  isOnlyOne?: boolean;
}
interface IPhoneItem {
  number: string;
  showPhone: boolean;
  toggle: () => void;
  containerClassName?: string;
  isMe?: boolean;
  isOnTelephony?: boolean;
  isOnlyOne?: boolean;
  showQR?: boolean;
  setShowQR: () => void;
}

const basePhoneClass =
  'font-head-light text-primary dark:text-grey-12 text-16 text-center py-4 px-[8px] rounded-8 border-primary dark:border-grey-12 border-1 border-opacity-25';

const YOU_FORMATTED = `(${YOU})`;

export const PhoneNumber = ({
  number,
  showCallIcon,
  isMe = false,
  showQR = false,
  setShowQR,
  isOnlyOne = false,
}: IPhoneNumber) => {
  const numberUri = `tel:${number}`;
  const [showQRInside, setShowQRInside] = useToggle(false);

  const isMobile = useIsMobile();

  const onClickRole = () => {
    if (!isOnlyOne && !showQR && !isMobile) {
      setShowQR();
      setShowQRInside();
    } else if (!isOnlyOne && showQR && !isMobile) {
      setShowQR();
    } else if (isMobile) {
      window.location.href = numberUri;
    }
  };
  return (
    <div className="flex w-full cursor-pointer" onClick={onClickRole}>
      {!isOnlyOne && (
        <>
          <a
            className={classNames('hidden mobile:flex', {
              'items-center w-full justify-between gap-x-[8px]': showCallIcon,
            })}
            href={numberUri}>
            {number}
            {isMe && <div className="ml-10">{YOU_FORMATTED}</div>}
          </a>
          <div className="mobile:hidden flex items-center">
            {number}
            {isMe && <div className="ml-10">{YOU_FORMATTED}</div>}
          </div>
          <Button
            className="mobile:hidden mr-0 ml-auto"
            Icon={<Icon variant="QR" className="dark:fill-white" />}
          />
        </>
      )}
      <Modal
        title={''}
        showModal={isOnlyOne ? showQR : showQRInside}
        setShowModal={isOnlyOne ? setShowQR : setShowQRInside}
        showControls={false}>
        <div className="flex flex-col gap-y-6 items-center justify-center pb-[50px]">
          <QRCode value={numberUri} />
          <p className={basePhoneClass}>{number}</p>
        </div>
      </Modal>
    </div>
  );
};

export const PhoneItem = ({
  number,
  showPhone,
  toggle,
  containerClassName,
  isMe,
  isOnTelephony = false,
  isOnlyOne = false,
  showQR = false,
  setShowQR,
}: IPhoneItem) => {
  const phoneClass = classNames(
    basePhoneClass,
    'flex w-full items-center justify-between mobile:justify-center',
    {
      hidden: !showPhone && !showQR,
      block: showPhone && showQR,
      'h-[0px] border-[0px] px-[0px] py-[0px]': showQR && isOnlyOne,
    }
  );

  return (
    <div
      className={classNames(
        'flex flex-row-reverse mb-[3px]',
        containerClassName
      )}>
      <div className={phoneClass} data-testid="phone">
        <PhoneNumber
          number={number}
          isMe={isMe}
          showQR={showQR}
          setShowQR={setShowQR}
          isOnlyOne={isOnlyOne}
        />
      </div>
    </div>
  );
};
