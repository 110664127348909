import React, { useState, ChangeEvent, useEffect } from 'react';
import Icon from '../../Icon/Icon';
import Title from '../../Title/Title';
import { COMING_SOON } from '../../../utils/constants';
import classNames from 'classnames';

interface IDropdownOption {
  value: string;
  selected: boolean;
}

interface IDropdown {
  options: IDropdownOption[];
  title: string;
  handleChange?: (value: string) => void;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  disabledOption?: string;
}

const containerClassNames = 'relative mt-8';
const selectClassNames =
  'bg-white w-full border-1 border-primary rounded-4 py-12 pl-14 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90';
const arrowClassNames =
  'absolute inset-y-0 right-0 flex items-center p-16 pointer-events-none';

const Dropdown = ({
  options,
  title,
  disabled,
  className,
  inputClassName,
  handleChange,
  disabledOption = COMING_SOON,
  ...others
}: IDropdown) => {
  const [selectedOption, setSelectedOption] = useState(
    options?.find((option) => option?.selected)?.value
  );

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    handleChange?.(event.target.value);
  };

  useEffect(() => {
    setSelectedOption(options?.find((option) => option?.selected)?.value);
  }, [options]);

  const renderOptions = () => {
    return disabled ? (
      <option key={disabledOption}>{disabledOption}</option>
    ) : (
      options?.map(({ value }) => <option key={value}>{value}</option>)
    );
  };

  return (
    <Title
      title={title}
      {...others}
      className={classNames({
        'pt-24 pb-8': !className,
        [className]: className,
      })}>
      <div className={containerClassNames}>
        <select
          disabled={disabled}
          value={selectedOption}
          onChange={(e) => {
            onChange(e);
          }}
          className={classNames({
            'opacity-50': disabled,
            [selectClassNames]: !inputClassName,
            [inputClassName]: inputClassName,
          })}>
          {renderOptions()}
        </select>
        {!disabled && (
          <div className={arrowClassNames}>
            <Icon
              variant="arrowExpand"
              className="fill-primary dark:fill-grey-12"
            />
          </div>
        )}
      </div>
    </Title>
  );
};

export default Dropdown;
