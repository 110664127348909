import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

interface IModal {
  setShowModal: any;
  showModal: boolean;
  title: string;
  children: React.ReactNode;
  onCancel?: () => void;
  onSubmit?: () => void;
  submitText?: string;
  cancelText?: string;
  formId?: string;
  showControls?: boolean;
  className?: string;
  exitButton?: boolean;
}

const Modal = ({
  setShowModal,
  showModal,
  title,
  children,
  onSubmit,
  onCancel,
  submitText = 'Submit',
  cancelText = 'Cancel',
  formId,
  showControls = true,
  className,
  exitButton = true,
}: IModal) => {
  const handleCancel = () => {
    onCancel?.();
  };
  if (!showModal) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div className="justify-center items-center flex fixed inset-0 z-[57]  ">
        <div
          className={classNames(
            'my-6 w-full max-w-[500px] max-h-full flex tablet:items-center justify-center',
            {
              'mobile:mx-5': !exitButton,
            }
          )}>
          <div className="rounded-8 shadow-lg flex flex-col w-full bg-white mobile:bg-grey-6 mobile:dark:bg-black dark:bg-grey-90 tablet:justify-between mobile:justify-between">
            <div className="flex items-start justify-between p-5 ">
              <h3 className="text-primary">{title}</h3>
              {exitButton && (
                <Button
                  onClick={() => setShowModal(null)}
                  Icon={
                    <Icon
                      variant="close"
                      className="fill-primary dark:fill-grey-12 "
                      width={13}
                      height={13}
                    />
                  }
                />
              )}
            </div>
            <div className={classNames('px-6', className)}>{children}</div>
            {showControls && (
              <div className="flex items-center justify-around p-6">
                <Button
                  text={submitText}
                  textClassName="text-white text-14 dark:text-grey-90 max-w-[102px] pl-[8px]"
                  className="font-head-bold w-[158px] py-13 justify-center rounded-4 flex items-center h-44 bg-primary dark:bg-grey-12"
                  onClick={onSubmit}
                  form={formId}
                  type={formId ? 'submit' : 'button'}
                />
                {onCancel && (
                  <Button
                    text={cancelText}
                    textClassName="text-primary text-14 dark:text-grey-12 max-w-[102px] pl-[8px]"
                    className="font-head-bold w-[158px] py-13 flex justify-center rounded-4 "
                    onClick={handleCancel}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 bg-black z-[56]"></div>
    </>,
    document.body
  );
};

export default Modal;
