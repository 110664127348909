import { gql } from '@apollo/client';
import { ERROR_FRAGMENT } from '../fragments';
import { NOTIFICATION_FRAGMENT } from './notifications.fragments';

export const GET_NOTIFICATIONS_HISTORY = gql`
  query NotificationsHistory($status: NotificationStatus) {
    notificationsHistory(status: $status) {
      data {
        _id
        userId

        notification {
          ...NotificationFragment
        }
        read
        createdAt
        updatedAt
      }
      error {
        ...ErrorFragment
      }
    }
  }
  ${NOTIFICATION_FRAGMENT}
  ${ERROR_FRAGMENT}
`;

export const GET_ALL_NOTIFICATIONS = gql`
  query Notifications($type: [NotificationType!]) {
    notifications(type: $type) {
      title
      subtitle
      description
      type
      category
      _id
      details {
        flightId
        station
        schedule {
          deleteDate
          sendDate
        }
        createdBy
      }
      createdAt
      updatedAt
      status
      sentAt
    }
  }
`;

export const GET_NOTIFICATION_META = gql`
  query NotificationTypes {
    notificationTypes {
      type
      categories
    }
  }
`;
