import React from 'react';
import classNames from 'classnames';
import AppInfo from '../../app.json';

interface IAppVersion {
  isHidden: boolean;
  isLoginPage?: boolean;
}

const AppVersion = ({ isHidden, isLoginPage }: IAppVersion) => {
  return (
    <div
      className={classNames({
        hidden: isHidden,
        'flex justify-center': !isHidden,
      })}>
      <div
        className={classNames('w-fit self-center px-4 mt-16 flex', {
          'text-white dark:text-white pb-5': isLoginPage,
          'text-primary dark:text-grey-40': !isLoginPage,
        })}>
        Turnaround Companion v{AppInfo?.version ?? 'none'}
      </div>
    </div>
  );
};

export default AppVersion;
