import React, { FormEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_SUBJECT,
  NOTIFICATION_MODAL_BODY,
} from '../../../utils/constants';
import {
  Notification,
  NotificationType,
} from '../../../utils/generated/graphql';
import Dropdown from '../../Settings/Dropdown/Dropdown';
import Datetime from 'react-datetime';
import Title from '../../Title/Title';
import {
  select24Format,
  selectStationsList,
} from '../../../redux/reducers/settingsReducer';
import 'react-datetime/css/react-datetime.css';
import './NotificationForm.scss';
import { NOTIFICATION_FORM_ID } from '../AdminNotificationCenter';
import Input from '../../Input/Input';
import TextArea from '../../TextArea/TextArea';
import { useGetNotificationMeta } from '../../../utils/hooks/useGetNotificationMeta';

const titleClass = 'flex items-center mobile:items-start mobile:flex-col';
const inputContainerClass =
  'relative bg-white w-full border-1 border-primary rounded-4 py-2 pl-14 pr-[7px] font-body-bold text-primary text-14 appearance-none focus:outline-none mt-8 dark:bg-grey-90 dark:border-grey-12 dark:text-grey-12';
const inputClass =
  'w-full appearance-none focus:outline-none pr-8 mb-8 dark:bg-grey-90';
const titleColorClass = 'w-[70px] text-grey-40';

const IS_CATEGORY_VISIBLE = false;

const FIELD_NAMES = {
  TYPE: 'Type',
  CATEGORY: 'Category',
  STATION: 'Station',
  SUBJECT: 'Subject',
  MESSAGE: 'Message',
  START: 'Start Date',
  END: 'End Date',
};

const DATE_FORMAT = 'DD MMM YYYY';

interface INotificationForm {
  notification?: Notification;
  handleSubmit?: (
    e: FormEvent<HTMLFormElement>,
    startDate: string,
    endDate: string
  ) => void;
}

export const NotificationForm = ({
  notification = undefined,
  handleSubmit,
}: INotificationForm) => {
  const { data, loading } = useGetNotificationMeta(!!notification);
  const [type, setType] = useState<NotificationType | string | null>(null);
  const [startDateValue, setStartDateValue] = useState(
    notification?.details?.schedule?.sendDate ?? ''
  );
  const [endDateValue, setEndDateValue] = useState(
    notification?.details?.schedule?.deleteDate ?? ''
  );
  const is24Format = useSelector(select24Format);

  const TIME_FORMAT = is24Format ? 'HH:mm' : 'hh:mm A';
  const DATE_TIME_FORMAT = 'DD MMM '.concat(TIME_FORMAT);

  useEffect(() => {
    notification ? setType(notification.type) : setType(data?.[0].type ?? null);
  }, [data, notification]);

  const typeOptions =
    data?.map((notificationType) => ({
      value: notificationType.type,
      selected: false,
    })) ?? [];

  const defaultTypeOptions = notification
    ? typeOptions?.map((option) => ({
        ...option,
        selected: option.value === notification.type,
      }))
    : typeOptions;

  const categoryOptions = data
    ?.find((notificationType) => notificationType.type === type)
    ?.categories?.map((category) => ({ value: category, selected: false }));

  const defaultCategoryOptions = notification
    ? categoryOptions?.map((option) => {
        return { ...option, selected: option.value === notification.category };
      })
    : categoryOptions;

  const formattedStartDate = startDateValue
    ? moment(startDateValue).format(DATE_TIME_FORMAT)
    : '';
  const formattedEndDate = endDateValue
    ? moment(endDateValue).format(DATE_TIME_FORMAT)
    : '';
  const stationOptions = useSelector(selectStationsList)?.map((station) => ({
    value: station,
    selected: false,
  }));

  const defaultStationOptions =
    type === 'STATION'
      ? notification
        ? stationOptions.map((station) =>
            station.value === notification.details.station
              ? { ...station, selected: true }
              : station
          )
        : stationOptions
      : [];

  return (
    <form
      id={NOTIFICATION_FORM_ID}
      onSubmit={(e) =>
        handleSubmit && handleSubmit(e, startDateValue, endDateValue)
      }>
      <div className="max-h-screen space-y-[10px]">
        <div className="bg-white border border-1 mobile:border-none dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90">
          <h1 className="text-primary">
            {NOTIFICATION_MODAL_BODY.NOTIFICATION_CONFIGURATION}
          </h1>
          <div className="flex justify-between pt-8">
            <Dropdown
              disabled={!!notification || loading}
              disabledOption={notification?.type ?? ''}
              inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
              className="p-0 w-[120px]"
              options={defaultTypeOptions}
              handleChange={(e) => setType(e)}
              title={FIELD_NAMES.TYPE}
            />
            {IS_CATEGORY_VISIBLE ? (
              <Dropdown
                disabled={!!notification || loading}
                disabledOption={notification?.category ?? ''}
                inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
                className="p-0 w-[135px]"
                options={defaultCategoryOptions ?? []}
                title={FIELD_NAMES.CATEGORY}
              />
            ) : (
              <Dropdown
                handleChange={() => {}}
                disabled={defaultStationOptions.length === 0}
                disabledOption=""
                inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
                className="p-0 w-[135px]"
                options={defaultStationOptions}
                title={FIELD_NAMES.STATION}
              />
            )}
          </div>
        </div>
        {IS_CATEGORY_VISIBLE && (
          <div className="bg-white border mobile:border-none border-1 dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90">
            <h1 className="text-primary">
              {NOTIFICATION_MODAL_BODY.TARGET_AUDIENCE}
            </h1>
            <Dropdown
              inputClassName="w-full border-1 border-black rounded-4 py-8 pl-8 font-body-bold text-primary text-14 appearance-none focus:outline-none dark:border-grey-12 dark:text-grey-12 dark:bg-grey-90"
              className="p-0 w-[135px]"
              options={defaultStationOptions}
              title={FIELD_NAMES.STATION}
            />
          </div>
        )}
        <div className="bg-white border border-1 mobile:border-none dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90 mobile:space-y-[8px]">
          <h1 className="text-primary">{NOTIFICATION_MODAL_BODY.MESSAGE}</h1>
          <Title
            titleColorClass={titleColorClass}
            title={FIELD_NAMES.SUBJECT}
            className={titleClass}>
            <Input
              containerClassName={inputContainerClass}
              inputClassName={inputClass}
              maxLength={MAX_LENGTH_SUBJECT}
              defaultValue={notification?.subtitle}
            />
          </Title>
          <Title
            titleColorClass={titleColorClass}
            title={FIELD_NAMES.MESSAGE}
            className={titleClass}>
            <TextArea
              containerClassName={inputContainerClass}
              areaClassName={inputClass}
              rows={2}
              maxLength={MAX_LENGTH_DESCRIPTION}
              defaultValue={notification?.description}
            />
          </Title>
        </div>
        <div className="bg-white border border-1 mobile:border-none dark:border-grey-60 rounded-8 px-24 py-8 dark:bg-grey-90">
          <h1 className="text-primary">{NOTIFICATION_MODAL_BODY.SCHEDULE}</h1>
          <div className="flex justify-between mobile:flex-wrap mobile:gap-y-8 mt-8 gap-x-8">
            <Title
              titleColorClass={titleColorClass}
              title={FIELD_NAMES.START}
              className="mobile:min-w-[140px] flex-1">
              <Datetime
                renderInput={(props) => (
                  <input {...props} readOnly value={formattedStartDate} />
                )}
                onChange={(value) => setStartDateValue(value.toString())}
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                initialValue={formattedStartDate}
                className="rdtPickerOpenUpwardsRight"
                inputProps={{
                  className:
                    'border-1 border-black dark:border-grey-12 rounded-4 p-8 mt-8 font-body-bold text-primary text-14 dark:bg-grey-90 dark:text-grey-12 w-full',
                }}
              />
            </Title>
            <Title
              titleColorClass={titleColorClass}
              title={FIELD_NAMES.END}
              className="mobile:min-w-[140px] flex-1">
              <Datetime
                renderInput={(props) => (
                  <input {...props} readOnly value={formattedEndDate} />
                )}
                onChange={(value) => setEndDateValue(value.toString())}
                dateFormat={DATE_FORMAT}
                timeFormat={TIME_FORMAT}
                initialValue={formattedEndDate}
                className="rdtPickerOpenUpwardsLeft"
                inputProps={{
                  className:
                    'border-1 border-black dark:border-grey-12 font-body-bold text-primary text-14 rounded-4 p-8 mt-8 dark:bg-grey-90 dark:text-grey-12 w-full',
                }}
              />
            </Title>
          </div>
        </div>
      </div>
    </form>
  );
};

export default NotificationForm;
