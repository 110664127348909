import { useEffect } from 'react';
import appConfig from '../../app.json';

export const useVersionChange = (callback) => {
  useEffect(() => {
    const currentVersion = appConfig.version;
    const storedVersion = localStorage.getItem('appVersion');
    console.log({ currentVersion });
    console.log({ storedVersion });

    if (storedVersion !== currentVersion) {
      callback();
      localStorage.setItem('appVersion', currentVersion);
    }
  }, [callback]);
};
