import { useEffect, useState } from 'react';
import { MOBILE_BREAKPOINT } from '../constants';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(
    window.innerWidth <= MOBILE_BREAKPOINT
  );

  const handleResize = () => {
    setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);
  };

  useEffect(() => {
    setIsMobile(window.innerWidth <= MOBILE_BREAKPOINT);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};
