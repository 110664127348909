import React from 'react';
import { useToggle } from '../../utils/hooks/useToggle';
import { PhoneItem } from './Phone';
import { CachedUsers } from '../../utils/generated/graphql';
import classNames from 'classnames';
import { useIsMobile } from '../../utils/hooks/useIsMobile';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { callNumber } from '../../utils/callNumber';

const textClass =
  'flex items-center font-head-bold text-primary max-h-[66px] mobile:max-h-[76px] dark:text-grey-12 text-18 w-full py-[19px] ml-0 pr-[40px] dark:bg-grey-90 pl-24 dark:border-b-grey-60 border-b-1 border-b-grey-12 mobile:border-l-0 border-white dark:border-l-grey-90';

interface ITeamContactItem {
  users: CachedUsers[];
  roleName: string;
}

const StationContactItem = ({ users, roleName }: ITeamContactItem) => {
  const [showPhone, toggle] = useToggle(false);
  const [showQR, setShowQR] = useToggle(false);

  const isMobile = useIsMobile();

  const isOnlyOne = users.length <= 1;

  const showNumberOnMobile = isMobile ? false : isOnlyOne;

  return (
    <div className="flex flex-col">
      <div className="relative flex justify-between flex-col">
        <div
          onClick={() =>
            callNumber(
              showNumberOnMobile,
              users?.[0].phoneNumber,
              showQR,
              setShowQR,
              toggle,
              isMobile
            )
          }>
          <div
            className={classNames(textClass, {
              'border-none': showPhone,
            })}
            role="status">
            {roleName}
          </div>
          <Button
            Icon={
              <Icon
                className="cursor-pointer dark:fill-grey-12 block"
                variant="call"
                height={18}
              />
            }
            className={classNames(
              'absolute m-auto desktop:top-[26px] laptop:top-[26px] tablet:top-[26px] right-24 mobile:top-[27px]',
              {
                'm-auto desktop:top-[26px] laptop:top-[26px] tablet:top-[26px] right-24 mobile:top-[27px]':
                  true,
              }
            )}
          />
        </div>
        <div
          className={classNames('', {
            'border-b-1 pb-8 px-24': showPhone,
            'h-[0px]': !showPhone,
          })}>
          {users.map((phone) => (
            <PhoneItem
              key={phone.externalID}
              number={phone.phoneNumber}
              showPhone={showPhone}
              toggle={toggle}
              isOnlyOne={showNumberOnMobile}
              showQR={showQR}
              setShowQR={setShowQR}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default StationContactItem;
