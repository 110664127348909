import { gql } from '@apollo/client';

export const TIME_OBJECT = gql`
  fragment TimeObject on TimeObject {
    UTCDiff
    UTCTime
    airportCode
    bestTimeLocal
    bestTimeLocal12format
    bestTimeUTC
    bestTimeUTC12format
    localTime
  }
`;

export const BEST_TIME = gql`
  fragment BestTime on BestTimeLogic {
    timeLeft
    timeLogicUsed
    delay
    time
    time12format
    timeString
  }
`;

export const USER_SETTINGS_FRAGMENT = gql`
  fragment UserSettings on UserSettingsOutput {
    station
    stationsList
    theme
    phoneNumber
    useUTC
    use24
    name
    email
    firstName
    userName
    favoriteFlightsMuted
    stationMuted
    roles {
      roleGroup
      name
      technicalRole
      carrier
      permissions
      _id
    }
    chatMuted
    nativeNotificationsMuted
    isPhoneReminderActive
  }
`;

export const ERROR_FRAGMENT = gql`
  fragment ErrorFragment on DataError {
    code
    description
    message
  }
`;

export const MOST_PENALISING_REGULATION_CAUSE = gql`
  fragment PenalisingCause on MostPenalisingRegulationCause {
    iataDelayCode
    reason
    locationCategory
  }
`;

export const ETD_DELAY_CODES = gql`
  fragment EtdDelayCodes on FlightEtd {
    codes {
      code
      subcode
      delay
    }
  }
`;
