import { useMutation } from '@apollo/client';
import { CHECK_OUT_FLIGHT } from '../../graphql/checkInFlight/check-in-flight.mutations';
import {
  Mutation,
  CheckOutFlightInput,
  CheckOutFlightOutput,
  Permission,
} from '../generated/graphql';
import { IMutationResponse } from '../types';
import { GET_MY_FLIGHTS_CACHED } from '../../graphql/myFlights/my-flights.queries';
import { unsubscribeToFlightCheckin } from '../notifications/subscribeToFlight';
import useCheckPermissions from './useCheckPermissions';

interface CheckOutResponse extends IMutationResponse<CheckOutFlightOutput> {
  onCheckOut: (
    data: CheckOutFlightInput
  ) => Promise<CheckOutFlightOutput | null>;
}

export const useCheckOut = (): CheckOutResponse => {
  const [checkOut, { data, loading, error }] = useMutation<
    Pick<Mutation, 'checkOutFlight'>
  >(CHECK_OUT_FLIGHT, {
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: GET_MY_FLIGHTS_CACHED }],
  });

  const onCheckOut = async (data: CheckOutFlightInput) => {
    try {
      const result = await checkOut({
        variables: {
          flight: data,
        },
      });
      unsubscribeToFlightCheckin(data.checkOutInput.flightId);
      return result.data?.checkOutFlight ?? null;
    } catch (error) {
      throw error;
    }
  };

  return {
    data: data?.checkOutFlight ?? null,
    loading,
    error,
    onCheckOut,
  };
};
